<template>
  <div class="impot-societes-update-page page-layout">
    <PageHeader
      title="Modifier un impôt société"
      :has-back="true"
      @back="goBack"
    />

    <md-card class="meep-form">
      <md-card-content>
        <div class="meep-form-column meep-form-single-column">
          <!--montant min-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('montant_min') }">
              <label>Montant minimum</label>

              <md-input
                v-model="form.montant_min"
                v-validate="'required|decimal'"
                name="montant_min"
                type="montant_min"
              />

              <span class="md-error" v-show="errors.has('montant_min')">{{
                errors.first("montant_min")
              }}</span>
            </md-field>
          </div>

          <!--montant max-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('montant_max') }">
              <label>Montant maximum</label>
              <md-input
                v-model="form.montant_max"
                v-validate="'required|decimal'"
                name="montant_max"
                type="montant_max"
              />
              <span class="md-error" v-show="errors.has('montant_max')">{{
                errors.first("montant_max")
              }}</span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('coef_bas') }">
              <label>Coef bas</label>

              <md-input
                v-model="form.coef_bas"
                v-validate="'required|decimal'"
                name="coef_bas"
                type="coef_bas"
              />

              <span class="md-error" v-show="errors.has('coef_bas')">{{
                errors.first("coef_bas")
              }}</span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('coef_haut') }">
              <label>coef haut</label>

              <md-input
                v-model="form.coef_haut"
                v-validate="'required|decimal'"
                name="coef_haut"
                type="coef_haut"
              />

              <span class="md-error" v-show="errors.has('coef_haut')">{{
                errors.first("coef_haut")
              }}</span>
            </md-field>
          </div>

          <div class="md-layout md-alignment-center-right">
            <md-button
              class="md-raised md-primary"
              @click.native="openConfirmDeleteModal"
            >
              Supprimer
            </md-button>

            <md-button
              class="md-raised md-primary"
              @click.native="updateImpotSocietes"
              >Valider
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <confirm-action-modal
      v-if="isConfirmDeleteModalOpen"
      :text="confirmDeleteModalText"
      :objectToActUpon="form"
      @close="closeConfirmDeleteModal"
      @confirm="removeSocietes"
    />
  </div>
</template>

<script>
import calculatorsModel from "../../../../model/calculators";
import confirmActionModal from "../../../../components/modal/confirm-action";
import PageHeader from "@/components/PageHeader";

export default {
  data() {
    return {
      isDebug: false,
      form: {
        montant_min: "",
        montant_max: "",
        coef_bas: "",
        coef_haut: "",
      },
      isConfirmDeleteModalOpen: false,
      confirmDeleteModalText: {
        header: "Suppression d'un impôt",
        body() {
          return "Vous êtes sur le point de supprimer l'impôt sur les sociétés";
        },
        question: "Êtes-vous certain de vouloir le faire ?",
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    calculatorsModel
      .getSocietes(to.params.id)
      .then(formData => {
        next(vm => {
          vm.form = formData;
          if (vm.isDebug) {
            console.log("impotSocietes with id" + to.params.id + ":");
            console.log(vm.form);
          }
        });
      })
      .catch(() => {
        next(from.path);
      });
  },
  components: {
    PageHeader,
    "confirm-action-modal": confirmActionModal,
  },
  methods: {
    goBack() {
      window.history.back();
    },
    updateImpotSocietes() {
      this.$validator.validateAll().then(result => {
        if (result) {
          calculatorsModel
            .updateSocietes(this.form)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: "L'impot de societe a bien été modifié",
              });
              this.$router.push("/admin/calculators/impot-societes/list");
            })
            .catch(err => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
    removeSocietes() {
      calculatorsModel
        .removeSocietes(this.form.id)
        .then(() => {
          this.$toasted.global.AppSucces({
            message: "L'impot a suprimé",
          });
          this.$router.push("/admin/calculators/impot-societes/list");
        })
        .catch(err => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },
    openConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.impot-societes-update-page {
  .debug-log {
    max-height: 360px;
    overflow-y: scroll;
  }

  .md-card {
    margin: 32px auto;
  }
}
</style>
